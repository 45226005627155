/* eslint-disable no-debugger */
import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { IArticle } from './DataServiceEntities.g';
import { createGetArticleByURLInput } from './DataActionExtension.g';

export default createObservableDataAction({
    action: <IAction<IArticle>>retailAction,
    input: (context: ICreateActionContext) => {
        let articleName = context.requestContext.url.requestUrl.pathname.split('/').at(-1);
        if (articleName === undefined) {
            articleName = '';
        }

        return createGetArticleByURLInput(articleName);
    }
});
